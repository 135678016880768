import { LOADING, SET_CURRENT_WEBSITE, SET_CURRENT_WEBSITE_DATA, SET_CURRENT_WEBSITE_PLAN_DATA, SET_MESSAGE } from "../../Actions/Types";
import { DomainDetailsApi } from "../../Api/DomainApi/DomainDetailsApi"
import { getViolationsCountApi, getViolationsListApi } from "../../Api/ViolationsApi";
import parseISODate from "../../Helpers/parseISODate";

const DomainDetailsService = async (
    token: string,
    websiteID: number,
    dispatch: Function,
    t: Function
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // Domain Details
    await DomainDetailsApi(token, websiteID).then(async (domainResponse) => {

        //console.info("DomainDetailsAPI =>", domainResponse.status);

        // Check Domain Details Res
        if (domainResponse.status === 200) {

            // Scan Count
            await getViolationsCountApi(token, websiteID).then(async (scanDataRes) => {

                //console.info("getViolationsCountApi =>", scanDataRes.status);

                if (scanDataRes.status === 200) {

                    // Scan Violations Data API
                    await getViolationsListApi(token, websiteID, 0).then(async (scanErrorViolationRes) => {

                        if (scanErrorViolationRes.status === 200) {

                            await getViolationsListApi(token, websiteID, 1).then(async (scanSuccessViolationRes) => {

                                // Scan Data
                                if (scanSuccessViolationRes.status === 200) {

                                    // Accessibility Score Percentage
                                    let accessibilityScore: number;
                                    let successAccessibilityChecks: number = scanDataRes.data[0].successAccessibilityChecks;
                                    let failingAccessibilityChecks: number = scanDataRes.data[0].failingAccessibilityChecks;

                                    if ((successAccessibilityChecks + failingAccessibilityChecks) > 0) {
                                        accessibilityScore = (parseFloat((((successAccessibilityChecks) / (successAccessibilityChecks + failingAccessibilityChecks)) * 100).toFixed(2)))
                                    } else {
                                        accessibilityScore = 0;
                                    }

                                    // Violations Remediated Percentage
                                    let violationsRemediatedPercentage: number;
                                    let successViolations: number = scanSuccessViolationRes.total_counts;
                                    let failedViolations: number = scanErrorViolationRes.total_counts;

                                    if ((successViolations + failedViolations) > 0) {
                                        violationsRemediatedPercentage = (parseFloat((((successViolations) / (successViolations + failedViolations)) * 100).toFixed(2)))
                                    } else {
                                        violationsRemediatedPercentage = 0;
                                    }

                                    // Image Alt Remediated Percentage
                                    let imageAltRemediatedPercentage: number;
                                    let successImageAlt: number = scanDataRes.data[0].total_update_alt_text;
                                    let failedImageAlt: number = scanDataRes.data[0].missing_alt_text;

                                    if ((successImageAlt + failedImageAlt) > 0) {
                                        imageAltRemediatedPercentage = (parseFloat((((successImageAlt) / (successImageAlt + failedImageAlt)) * 100).toFixed(2)))
                                    } else {
                                        imageAltRemediatedPercentage = 0;
                                    }

                                    // Set Current Website Data
                                    dispatch({
                                        type: SET_CURRENT_WEBSITE_DATA,
                                        payload: {
                                            "accessibilityScore": accessibilityScore,
                                            "violationsRemediatedPercentage": violationsRemediatedPercentage,
                                            "successViolations": successViolations,
                                            "failedViolations": failedViolations,
                                            "imageAltRemediatedPercentage": imageAltRemediatedPercentage,
                                            "successImageAlt": successImageAlt,
                                            "failedImageAlt": failedImageAlt,
                                            "successViolationsList": scanSuccessViolationRes.Data,
                                            "failedViolationsList": scanErrorViolationRes.Data,
                                        }
                                    });

                                    // Set Current Website Plan Details

                                    let isExpiredFlag: boolean = false;
                                    let isTrialFlag: boolean = false;
                                    let isInitFlag: boolean = false;
                                    let isFreeWidget: boolean = false;
                                    let isProPlanFlag: boolean = false;
                                    let isCanceledPlanFlag: boolean = false;
                                    let tempPlanName: string | null = null;
                                    let tempPlanExpiredData: string | null = null;
                                    let tempPlanType: string | null = null;

                                    if (domainResponse.Data && domainResponse.Data.length > 0) {
                                        if (domainResponse.Data[0].is_expired === 1) { // Expired Plan
                                            console.info("Expired Plan");
                                            if (domainResponse.Data[0].expired_package_detail) {
                                                isExpiredFlag = true;
                                                isTrialFlag = false;
                                                isInitFlag = false;
                                                isFreeWidget = false;
                                                isCanceledPlanFlag = false;
                                                isProPlanFlag = false;
                                                tempPlanName = domainResponse.Data[0].expired_package_detail[0].name;
                                                tempPlanType = domainResponse.Data[0].expired_package_detail[0].platforms;
                                                tempPlanExpiredData = parseISODate(domainResponse.Data[0].expired_package_detail[0].end_date);
                                            }

                                        } else if ((domainResponse.Data[0].is_expired === 3) && (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0)) { // Trial Plan
                                            console.info("Trial Plan");
                                            isExpiredFlag = false;
                                            isTrialFlag = true;
                                            isCanceledPlanFlag = false;
                                            isProPlanFlag = false;
                                            isFreeWidget = false;
                                            isInitFlag = (successViolations === 0 && failedViolations === 0) ? true : false;
                                            tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                            tempPlanType = domainResponse.Data[0].current_package_only[0].platforms;
                                            tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                        } else if (domainResponse.Data[0].canceled_package_only && domainResponse.Data[0].canceled_package_only.length > 0) { // Canceled Plan

                                            console.info("Canceled Plan");

                                            isExpiredFlag = false;
                                            isTrialFlag = false;
                                            isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                            isFreeWidget = false;
                                            isProPlanFlag = false;
                                            isCanceledPlanFlag = true;
                                            tempPlanName = domainResponse.Data[0].canceled_package_only[0].name;
                                            tempPlanType = domainResponse.Data[0].canceled_package_only[0].platforms;
                                            tempPlanExpiredData = parseISODate(domainResponse.Data[0].canceled_package_only[0].end_date);

                                        } else {

                                            if (domainResponse.Data[0].current_package_only && domainResponse.Data[0].current_package_only.length > 0) {

                                                console.info("Free Widget");

                                                if (domainResponse.Data[0].current_package_only[0].is_free_widget === 1) {

                                                    // Free Plan Widget
                                                    isExpiredFlag = false;
                                                    isTrialFlag = false;
                                                    isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                    isFreeWidget = true;
                                                    isProPlanFlag = false;
                                                    isCanceledPlanFlag = false;
                                                    tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                    tempPlanType = domainResponse.Data[0].current_package_only[0].platforms;
                                                    tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);

                                                } else {

                                                    console.info("Pro Widget");

                                                    // Pro Plan Widget
                                                    isExpiredFlag = false;
                                                    isTrialFlag = false;
                                                    isInitFlag = ((successViolations === 0 && failedViolations === 0) ? true : false);
                                                    isFreeWidget = false;
                                                    isCanceledPlanFlag = false;
                                                    isProPlanFlag = true;
                                                    tempPlanName = domainResponse.Data[0].current_package_only[0].name;
                                                    tempPlanType = domainResponse.Data[0].current_package_only[0].platforms;
                                                    tempPlanExpiredData = parseISODate(domainResponse.Data[0].current_package_only[0].end_date);
                                                }

                                            } else {

                                                console.info("Null Widget");

                                                // No Plan Found
                                                isExpiredFlag = false;
                                                isTrialFlag = false;
                                                isInitFlag = false;
                                                isFreeWidget = false;
                                                isProPlanFlag = false;
                                                isCanceledPlanFlag = false;
                                                tempPlanName = null;
                                                tempPlanExpiredData = null;
                                                tempPlanType = null;
                                            }

                                        }
                                    }
                                    dispatch({
                                        type: SET_CURRENT_WEBSITE_PLAN_DATA,
                                        payload: {
                                            isExpired: isExpiredFlag,
                                            isTrial: isTrialFlag,
                                            isInit: isInitFlag,
                                            isFreeWidget: isFreeWidget,
                                            isProPlan: isProPlanFlag,
                                            isCanceledPlan: isCanceledPlanFlag,
                                            planName: tempPlanName,
                                            planType: tempPlanType,
                                            planExpiredData: tempPlanExpiredData
                                        }
                                    });

                                    // Set Current Website Data
                                    dispatch({
                                        type: SET_CURRENT_WEBSITE,
                                        payload: domainResponse.Data[0]
                                    });

                                    // Set Loading
                                    dispatch({
                                        type: LOADING,
                                        payload: false,
                                    });

                                    //console.info("Promise.resolve call");

                                    // Res Msg
                                    /* dispatch({
                                        type: SET_MESSAGE,
                                        payload: {
                                            "toast_message": t('Login Success Msg'),
                                            "toast_type": "success",
                                            "onclose": null
                                        }
                                    }) */
                                }
                            })

                        }

                    })

                }
            })

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            //return Promise.resolve(200);

            // Res Msg
            /* dispatch({
                type: SET_MESSAGE,
                payload: {
                    "toast_message": t('Login Fail Msg'),
                    "toast_type": "danger",
                }
            }) */
        }

    })
    return { status: 200 };

}

const DomainDetailsWithoutViolationsService = async (
    token: string,
    websiteID: number,
    dispatch: Function,
    t: Function
): Promise<any> => {

    // Set Loading
    dispatch({
        type: LOADING,
        payload: true,
    });

    // Domain Details
    const DomainDetailsApiRes = DomainDetailsApi(token, websiteID).then(async (domainResponse) => {

        // Check Domain Details Res
        if (domainResponse.status === 200) {

            // Set Current Website Data
            dispatch({
                type: SET_CURRENT_WEBSITE,
                payload: domainResponse.Data[0]
            });

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { status: 200 }

        } else {

            // Set Loading
            dispatch({
                type: LOADING,
                payload: false,
            });

            return { status: 400 }
        }

    })
    return DomainDetailsApiRes;

}

export { DomainDetailsService }
