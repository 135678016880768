import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StoreProptypes } from "../../PropTypes/StoreProptypes";
import { useEffect, useState } from "react";
import { websitePlanPropType } from "../../PropTypes/WebsitePlanPropType";
import encodeBase64 from "../../Helpers/encodeBase64";

const StrikePrice = ({ price, isDiscountPr, discountPr }: { price: number; isDiscountPr?: boolean; discountPr?: number }) => {
    let strikePrice: number = price;

    if (isDiscountPr && discountPr && discountPr > 0) {
        strikePrice = price * (1 - discountPr / 100);
    }

    return strikePrice.toFixed(2);
};

interface PropTypes {
    activePlanType: number, // 1-widget plan, 2- multi domain, 3 - Pre purchase
    activePlanInterval: string | null,
    activePlanPrice: number
    activePlanDomain: string,
    forAddNewDomain?: boolean
}
const UpgradePlanList = (props: PropTypes) => {
    // Redux Data
    const { currentWebsitePlanList, user, activeLanguage, accessToken, currentUserDiscount, currentWebsite } = useSelector((store: StoreProptypes) => store);

    // Props
    const { activePlanPrice, activePlanInterval, activePlanType, activePlanDomain, forAddNewDomain } = props;

    // Functions
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [upgradePlanList, setUpgradePlanList] = useState<websitePlanPropType[]>([]);
    const [planInterval, setPlanInterval] = useState<string>('Y');
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [freePlanID, setFreePlanID] = useState<number>();

    // Disable the "Select Plan" button for users in department 1
    useEffect(() => {
        if (user && user.department === 1) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }
    }, [user]);

    // Filter the plan list based on conditions and include plans with is_dynamic === 1 for activePlanType === 3 and department === 4
    useEffect(() => {
        if (currentWebsitePlanList && Object.keys(currentWebsitePlanList).length > 0) {
            let tempPlanList: websitePlanPropType[] = [];

            if (activePlanType === 3 && user && user.department === 4) {
                if (currentWebsite) {
                    if (currentWebsite.current_package_only[0].platforms === "dynamic") {
                        currentWebsitePlanList[`dynamic`]
                            .forEach(planList => {
                                if ((activePlanInterval === "M" || activePlanInterval === null) && planList.is_dynamic === 1) {
                                    tempPlanList = [...tempPlanList, planList];
                                } else if (activePlanInterval === "Y" && planList.is_dynamic === 1) {
                                    tempPlanList = [...tempPlanList, planList];
                                }
                            });
                    }

                    // Retain the original condition for non-dynamic plans
                    currentWebsitePlanList[`multi`]
                        .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                        .forEach(planList => {
                            if (activePlanInterval === "M" && planList.monthly_price >= activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            } else if (activePlanInterval === "Y" && planList.monthly_price > activePlanPrice) {
                                tempPlanList = [...tempPlanList, planList];
                            }
                        });
                }
            } else {
                // Retain original filtering logic for activePlanType !== 3
                currentWebsitePlanList[`normal`]
                    .filter(planList => planList.is_dynamic !== 1) // Exclude is_dynamic=1
                    .forEach(planList => {
                        if ((activePlanInterval === "M" || activePlanInterval === null) && planList.monthly_price >= activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList];
                        } else if (activePlanInterval === "Y" && planList.monthly_price > activePlanPrice) {
                            tempPlanList = [...tempPlanList, planList];
                        }
                    });
            }

            setUpgradePlanList(tempPlanList);
        }
    }, [activePlanType, activePlanInterval, activePlanPrice]);

    // Retrieve the ID of the free widget plan
    useEffect(() => {
        if (currentWebsitePlanList && currentWebsitePlanList["free-widget"]) {
            setFreePlanID(currentWebsitePlanList["free-widget"][0].id);
        }
    }, [currentWebsitePlanList]);

    // Handle widget plan purchase
    const handlePurchaseWidgetPlan = (planID: number) => {
        if (user && activeLanguage && planID) {
            const urlString = `${planID}|${activePlanDomain}|${planInterval}|${user.id}|${activeLanguage.code}|1`;
            const encodedString = encodeBase64(urlString);
            navigate(`/front/package/${encodedString}`);
        }
    };

    return (
        <>
            <div className="aioa_dashboard-widget-plan-list">
                
                    
                
                <Row className="gx-xl-0 gy-4" xs={1} md={2}
                    xl={(activePlanType === 2 || activePlanType === 3) ?
                        ((forAddNewDomain && user && user.department !== 4) ? 4 : 3) :
                        ((forAddNewDomain && user && user.department !== 4) ? 5 : 4)}>
                    {(upgradePlanList && upgradePlanList.length > 0) ? (
                        upgradePlanList.map((planItem) => (
                            <Col className={`${(activePlanType !== 2 && activePlanInterval === "M" && planInterval === 'M' && (planItem.monthly_price === activePlanPrice)) ? `d-none` : ``}`} key={planItem.id}>
                                <Card className="aioa_dashboard-widget-plan-box">
                                    <Card.Header className="h5">{planItem.name}</Card.Header>
                                    <Card.Body>
                                        <div className="aioa_dashboard-widget-plan-box-description">{t('Plan Description Text', { pageViews: planItem.page_views })} </div>

                                        <div className="aioa_dashboard-widget-plan-box-price">
                                            {user && user.department === 4 && currentUserDiscount && currentUserDiscount.discount_value > 0 ? (
                                                <>
                                                    {planInterval === "M" ? (
                                                        <>
                                                            <div className="aioa_dashboard-widget-plan-box-strike-price"><del>${planItem.monthly_price.toFixed(2)}<span>/{t('Per Month')}</span></del></div>
                                                            <div className="aioa_dashboard-widget-plan-box-offer-price">${StrikePrice({ price: planItem.monthly_price, isDiscountPr: true, discountPr: currentUserDiscount.discount_value })}<span>/{t('Per Month')}</span></div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="aioa_dashboard-widget-plan-box-strike-price">
                                                                <del>${planItem.price.toFixed(2)}<span>/{t('Per Year')}</span></del>
                                                            </div>
                                                            <div className="aioa_dashboard-widget-plan-box-offer-price">${StrikePrice({ price: planItem.price, isDiscountPr: true, discountPr: currentUserDiscount.discount_value })}<span>/{t('Per Year')}</span></div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                planInterval === "M" ? (
                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">${planItem.monthly_price}<span>/{t('Per Month')}</span></div>
                                                ) : (
                                                    <div className="aioa_dashboard-widget-plan-box-offer-price">${planItem.price}<span>/{t('Per Year')}</span></div>
                                                )
                                            )}
                                        </div>
                                        <div className="aioa_dashboard-widget-plan-box-btn">
                                            <Button variant="outline-primary" size="lg" onClick={() => handlePurchaseWidgetPlan(planItem.id)} disabled={disabledButton}>{t('Select Plan')}</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : <>
                        {t('Contact us to upgrade or purchase a plan.')}
                    </>}
                </Row>
            </div>
        </>
    );
};

export default UpgradePlanList;
